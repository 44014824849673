<template>
    <div>
        <div class="d-flex justify-content-between flex-wrap">
            <div class="mr-1 mb-1" v-if="interfaceSinistre === 'Listing'">
                 <actions-sinistre :gestionnaire-options="gestionnaireOptions" :assureur-options="assureursOptions"
                    :risque-options="risquesOptions" :current-user="currentUser" :is-update.sync="isUpdate"
                    :sinistre-for-edit="sinistreForEdit" @is-update-function="isUpdateFunction"
                    :prospect-options="prospectData" @fetch-data-ref="fetchDataRef"
                    @record-sinistre-returned="recordSinistreReturned" :projet-contrat-data="projetContratData"
                    :is-fiche-contrat="isFicheContrat"
                    :tiers-data="contratData"
                    @fetch-sinisters-list="fetchSinistersList"/> 

            </div>
        </div>
        <div v-if="interfaceSinistre === 'Listing'">
            <b-card>
                <div class="custom-search">
                    <b-form ref="form">
                            <!-- advance search input -->
                            <b-row>

                                <!-- <b-col md="3">
                                    <b-form-group label="Date de sinistre comprise entre le" label-for="entrele">
                                        <flat-pickr id="entrele" v-model="filtre.date_sinistre_entre_le"
                                            :config="configDate('entrele')" class="form-control"
                                            placeholder="Date de sinistre" />
                                    </b-form-group>
                                </b-col>
                                <b-col md="3">
                                    <b-form-group label="Et le" label-for="et_le">
                                        <flat-pickr id="et_le" v-model="filtre.date_sinistre_el_le"
                                            :config="configDate('et_le')" class="form-control"
                                            placeholder="Date de sinistre" />
                                    </b-form-group>
                                </b-col> -->
                                   <b-col md="3">
                                        <b-form-group label="Type de sinistre" label-for="typeSinistre">
                                            <b-form-select id="typeSinistre" v-model="selectedTypeSinistre"
                                                :options="typeSinistreOption" />
                                        </b-form-group>
                                    </b-col>
                          
                                <b-col md="3">
                                    <b-form-group label="Sinistre en délégation" label-for="sinistreDelegation">
                                        <b-form-select id="sinistreDelegation" v-model="selectedDelegation"
                                            :options="delegationOptions" />
                                    </b-form-group>
                                </b-col>
                                  <b-col md="2">
                                        <b-form-group label="Statut" label-for="statut">
                                            <b-form-select id="statut" v-model="selectedStatus" :options="statusOptions" />

                                        </b-form-group>
                                    </b-col>
                                <b-col md="2">
                                    <b-form-group label="Assureur" label-for="assureur">
                                        <b-form-select id="assureur" v-model="selectedAssureur"
                                            :options="assureur_options_list" />
                                    </b-form-group>
                                </b-col>
                                <b-col md="2">
                                    <b-form-group label="Risque" label-for="Risques">
                                        <b-form-select id="Risques" v-model="selectedRisque" :options="risquesOptions" />
                                    </b-form-group>
                                </b-col>
                         
                                <b-col md="3">
                                    <b-form-group label="Recherche" label-for="basic-recherche">
                                        <b-input-group>
                                            <b-form-input v-model="recherche" :lazy="true" id="basic-recherche"
                                                class="d-inline-block" type="text" placeholder="Recherche"
                                                @keyup.enter="searchInput" />
                                            <b-input-group-append is-text>
                                                <feather-icon icon="SearchIcon" class="cursor-pointer" />
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>

                                <b-col md="1" class="mt-1">
                                    <b-button v-b-tooltip.hover.top="'Tout afficher'" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        class="btn-icon mt-1" variant="primary" @click="clearAllFilter">
                                        <feather-icon icon="RefreshCwIcon" />
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                </div>
            </b-card>
            <b-card>
                <b-overlay :show="showLoading" no-wrap />
                <!-- table -->
                <vue-good-table :columns="columns"
                    :pagination-options="{
                        enabled: true,
                        perPage: pageLength
                    }"
                    @on-selected-rows-change="selectionChanged"
                    :rows="rows"
                    :rtl="direction"
                    :search-options="{
                        enabled: true,
                        externalQuery: searchTerm
                    }"
                    :select-options="{
                        enabled: true,
                        selectOnCheckboxOnly: true,
                        selectionInfoClass: 'custom-class',
                        selectionText: 'lignes sélectionnées',
                        clearSelectionText: 'Effacer la sélection',
                        disableSelectInfo: false,
                        selectAllByGroup: false
                    }">
                                        <template slot="table-row" slot-scope="props">
                        
                        <!-- Column : Sinistre -->
                        <span v-if="props.column.field === 'numero_sinistre'" @click="showSinistre(props.row)">
                            <a style="text-decoration: underline">{{ props.row.numero_sinistre }}
                            </a>
                        </span>
                        <!-- Column: client -->
                        <span v-else-if="props.column.field == 'client' &&
                            props.row.sinistre_client != ''
                            " @click="showProspect(props.row.sinistre_client.id,
                                props.row.sinistre_client.type
                            )
                            ">
                            <a style="text-decoration: underline">{{ props.row.client }}</a>
                        </span>

                        <!-- Column: statut -->
                        <span v-else-if="props.column.field === 'statut'">
                            {{ changeText(props.row.statut) }}
                        </span>

                        <span v-else-if="props.column.field === 'type_sinistre'">
                            {{ changeTextTypeSinistre(props.row.type_sinistre) }}
                        </span>

                        <!-- Column: client -->
                         <span v-else-if="props.column.field == 'delegation'">
                  {{ props.row.isDelegation == '1' ? 'Oui' : 'Non' }}
               </span> 
                        <!-- Column: Action -->
                        <span v-else-if="props.column.field === 'actions'">
                            <span>
                                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <feather-icon icon="MoreVerticalIcon" size="16"
                                            class="text-body align-middle mr-25" />
                                    </template>
                                    <b-dropdown-item v-if="can('Modify claim') || isPrincipal()"
                                        @click="editSinistre(props.row)" v-b-toggle.sidebar-sinistre>
                                        <feather-icon icon="Edit2Icon" class="mr-50" />
                                        <span>Modifier</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="clotureSinistre(props.row)">
                                        <feather-icon icon="ArchiveIcon" class="mr-50" />
                                        <span>Clôturer</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="deleteSinistre(props.row)">
                                        <feather-icon icon="TrashIcon" class="mr-50" />
                                        <span>Supprimer</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item 
                                        @click="joindreFile(props.row)"
                                        >
                                        <feather-icon class="mr-50" icon="PaperclipIcon" />
                                        <span>Joindre</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </span>
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <b-row class="mt-2 align-items-center">
                            <b-col md="6" lg="5" xxl="3">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        Affichage 1 à
                                    </span>
                                    <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1" @input="(value) => {
                                        props.perPageChanged({ currentPerPage: value });
                                    }" />
                                    <span class="text-nowrap"> de {{ allDataFprFetch.total }} entrées </span>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2 align-items-center">
                            <b-col>
                                <b-input-group>
                                    <b-form-select v-model="selected_action" :options="options" />
                                    <b-input-group-append>
                                        <b-button @click="action_masse(selected_action)"
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                                            <span>Valider </span>
                                            <feather-icon icon="ArrowRightIcon" class="mr-50" />
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                            <b-col md="8">
                                <b-pagination
                                :per-page="pageLength"
                                :total-rows="props.total"
                                :value="1"
                                align="right"
                                first-number
                                last-number
                                next-class="next-item"
                                prev-class="prev-item"
                                @input="(value) => props.pageChanged({ currentPage: value })"
                                >
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </template>
                    <div class="text-center align-middle" slot="emptystate">
                        <span>Aucune donn&eacute;e disponible dans le tableau</span>
                    </div>
                </vue-good-table>
            </b-card>

        </div>
          <ActionsDocument v-if="interfaceSinistre === 'Listing'" @fetch-document="$emit('refersh-document')" :sinistre = "sinistreList"  />

    </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import ActionsDocument from "@/views/portefeuilles/sinistres/ActionsDocument.vue"

import { BPagination, BFormGroup, VBTooltip, BFormInput, BFormSelect, BFormSelectOption, BRow, BCol, BButton, BForm, BCard, BDropdown, BDropdownItem, BOverlay, BInputGroup, BInputGroupAppend, VBToggle } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import sinistreOptions from "@/shared/constants/sinistre";
import actionsSinistre from '@/views/portefeuilles/sinistres/actions-sinistre'
import Ripple from 'vue-ripple-directive'
import moment from "moment";
export default {
    components: {
        ActionsDocument,
        flatPickr,
        VueGoodTable,
        BPagination,
        BFormGroup,
        actionsSinistre,
        VBTooltip,
        BFormInput,
        BForm,
        BFormSelect,
        BInputGroup,
        BInputGroupAppend,
        BRow,
        BCol,
        BButton,
        BCard,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BFormSelectOption,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
        moment,
        
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            sinistreList: null,
            pageLength: 3,
            selected_action: null,
            page: 1,
            currentUser: JSON.parse(localStorage.getItem('userData')),
            dir: false,
            isUpdate: false,
            interfaceSinistre: "Listing",
            selectedTypeSinistre: null,
            options: [
                { value: null, text: 'Choisissez' },
                { value: 'Supprime', text: 'Supprimer' },
                { value: 'clos', text: 'Clôturer' },
                // { value: 'Archiver', text: 'Archiver' },
                // { value: 'Supprimer', text: 'Supprimer' }
            ],
            optionsTypeClient: [
                { value: null, text: '-- Choisissez --' },
                { value: 'PARTICULIER', text: 'Particulier' },
                { value: 'PROFESSIONNEL', text: 'Professionnel' },
                { value: 'ENTREPRISE', text: 'Entreprise' }
            ],
            // filter
            selectedAssureur: null,
            selectedRisque: null,
            selectedStatus: null,
            selectedDelegation: null,
            recherche: null,
            selectedCommercial: null,
            filtre: {
                date_sinistre_entre_le: null,
                date_sinistre_el_le: null,
            },
            typeSinistreOption: sinistreOptions['TYPES'],
            selected: null,
            prospectData: [],
            sinistreForEdit: {
                id: null,
                numero_sinistre: null,
                canal_sinistre: null,
                date_acc_recep: null,
                is_client: false,
                is_contrat : false,
                sinistree: {
                    id: null,
                    type: null,
                    categorie: null,
                    client: null,
                    nom: null,
                    prenom: null
                },
                reference: {
                    id: null,
                    numero: null,
                    source: null,
                    type: null,
                    ref_externe: null,
                    assureur_id: null,
                    risque_id: null
                },
                risque_id: null,
                assureur_id: null,
                courtier_id: null,
                date_sinistre: null,
                type: null,
                statut: null,
                responsabilite: null,
                date_declaration: null,
                montant: null,
                isDelegation: 0,
                expert: null,
                date_cloture: null,
                gestionnaire: null,
            },

            clientsOptions: [],
            gestionnaireOptions: [],
            contratOptions: [],
            risquesOptions: [{ value: null, text: '-- Choisissez --' }],
            assureursOptions: [{ value: null, text: '-- Choisissez --' }],
            contratsOptions: [],
            statusOptions: sinistreOptions['STATUS'],
            delegationOptions: [
                {
                    value: null,
                    text: "-- Choisissez --",
                },
                {
                    value: true,
                    text: "oui",
                },
                {
                    value: false,
                    text: "non",
                },
            ],
            allDataFprFetch: {},
            assureur_list: [{
                value: null,
                text: '-- Choisissez --'
            }],
            assureur_options_list: [{
                value: null,
                text: '-- Choisissez --'
            }],

            columns: [
                {
                    label: 'N° sinistre',
                    field: 'numero_sinistre',
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                },
                {
                    label: 'Client',
                    field: 'client',
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                },
                {
                    label: "Date de sinistre",
                    field: "date_sinistre",
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                },
                {
                    label: "Date de clôture",
                    field: "date_cloture",
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                },
                {
                    label: 'Statut',
                    field: 'statut',
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                },
                {
                    label: 'Type sinistre',
                    field: 'type_sinistre',
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                },
                {
                    label: 'Délégation',
                    field: 'delegation',
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                },
                {
                    label: 'Risque',
                    field: 'risque',
                    tdClass: 'align-middle',
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                },
                {
                    label: 'Assureur',
                    field: 'assureur',
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                },
                {
                    label: 'Gestionnaire',
                    field: 'gestionnaire_nom',
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sortable: false,
                    thClass: "text-center align-middle bg-primary text-white unbreaking h6",
                    tdClass: "text-center m-0 p-1 align-middle h6",
                }
            ],
            rows: [],
            rowsConst: [],
            projetContratData: [],
            prospectChosen: {
                id: null,
                type: null,
            },

            searchTerm: '',
            showLoading: false,
            sinistreChosen: {
                id: null,
                type: null,
            },
            selectedrows_masse: []

        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        }
    },
    props: {
        contratData: {
            type: Object,
            default: null,
            required: true,

        },
        isFicheContrat: {
            type: Boolean,
            default: false,
            required: false
        },
        contartIdType: {
            type: Object,
            default: null,
            required: true
        }
    },
    watch: {
        selectedAssureur() {
            this.filterData();
        },
        selectedDelegation() {
            this.filterData()
            this.listassureurfilter()

        },
        selectedStatus() {
            this.filterData()
            this.listassureurfilter()

        },
        selectedRisque() {
            this.filterData()
            this.listassureurfilter()

        },
        selectedTypeSinistre() {
            this.filterData()
            this.listassureurfilter()

        },
        "filtre.date_sinistre_entre_le": {
            handler(val) {
                this.filterData();
                this.listassureurfilter()

            },
        },
        "filtre.date_sinistre_el_le": {
            handler(val) {
                this.filterData();
                this.listassureurfilter()

            },
        },
        contartIdType: {
            deep: true,
            immediate: true,
            handler(val) {
                if (val.itemId) {
                    this.fetchSinistersList()
                }
            }
        }
    },
    mounted() {
        this.fetchRisqueList()
        this.fetchAssureurList()
        this.fetchProspectData()
        this.fetchDataCommercialByCourtier()
    },
    methods: {
        joindreFile(sinistreList, form = false) {
            this.sinistreList = null
            this.sinistreList = sinistreList
            if (!form)
                this.$root.$emit('bv::toggle::collapse', 'sidebar-importNewSinistre')

        },
        selectionChanged(params) {
            this.selectedrows_masse = params;
        },
        action_masse(action) {
            switch (action) {
                case 'Supprime':
                    if (this.selectedrows_masse.selectedRows.length > 0) {
                        this.deleteSinistre(this.selectedrows_masse.selectedRows)
                    }
                    break;
                case 'clos':
                    if (this.selectedrows_masse.selectedRows.length > 0) {
                        this.clotureSinistre(this.selectedrows_masse.selectedRows)
                    }
                    break;
                default:
            }
        },
        deleteSinistre(row) {
            console.log('11111111')
            this.$swal({
                title: 'Etes-vous sûr de vouloir supprimer ce sinistre ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.showLoading = true;
                    this.$http
                        .post("/sinistre/deleteSinistre", {
                            data: row,
                        })
                        .then((res) => {
                            if (res.data.success) {
                                this.showLoading = false;
                                this.fetchSinistersList();
                                this.messageToast(
                                    "le sinistre est supprime",
                                    "Succès",
                                    "success"
                                );
                            }
                        })
                        .catch((err) => {
                            this.showLoading = false;
                            console.error(err);
                        });
                }
            });

        },
        clotureSinistre(row) {

            console.log('11111111')
            this.$swal({
                title: 'Etes-vous sûr de vouloir clôturer ce sinistre ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.showLoading = true;
                    this.$http
                        .post("/sinistre/cloture", {
                            data: row,
                        })
                        .then((res) => {
                            if (res.data.success) {
                                this.showLoading = false;
                                this.fetchSinistersList();
                                this.messageToast(
                                    "le sinistre est clôturer",
                                    "Succès",
                                    "success"
                                );
                            }
                        })
                        .catch((err) => {
                            this.showLoading = false;
                            console.error(err);
                        });
                }
            });

        },
        configDate(id) {
            return {
                dateFormat: "d/m/Y",
                allowInput: true,
                onKeyDown: (selectedDates, dateStr, instance, event) => {
                    if (event.keyCode !== 8) {
                        var ele = document.getElementById(id);
                        var val = ele.value;

                        if (
                            (val.length === 2 || val.length === 5) &&
                            ele.getAttribute("id")
                        ) {
                            val += "/";
                        }
                        ele.value = val;
                        ele.dispatchEvent(new Event("input"));
                    }
                },
            };
        },
        showSinistre(data) {
            this.editSinistre(data);
            this.$router.push({
                name: 'sinistres-list',
                params: {
                    'id': data.id,
                    'type': data.statut,
                    'interfaceSinistre': 'FicheSinistre',
                    'sinistreForEdit': this.sinistreForEdit
                }
            })
        },
        // Methode additional
        isUpdateFunction(boolValue) {
            this.isUpdate = boolValue
        },
        messageToast(text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Succès',
                    icon: 'BellIcon',
                    text,
                    variant: 'success'
                }
            })
        },

        // fetch Data
        fetchRisqueList() {
            this.risquesOptions = [{ value: null, text: '-- Choisissez --' }]
            this.$http
                .post('/risques/fetchRisqueList')
                .then(res => {

                    if (res.data.success) {
                        res.data.data.forEach(el => {
                            this.risquesOptions.push({
                                value: el.id,
                                text: el.label
                            })
                        })
                    }
                })
                .catch(err => {
                    this.risquesOptions = [{ value: null, text: '-- Choisissez --' }]
                    console.error(err)
                })
        },

        fetchDataRef(prospect) {

            this.projetContratData = [];
            this.prospectChosen.id = prospect.id;
            this.prospectChosen.type = prospect.type;
            this.fetchContratsListClient();
        },
        fetchContratsListClient() {

            this.$http
                .post("/contrat/fetchDataContrats", {
                    courtier_user_id: this.currentUser.courtier_user[0].id,
                    user_id: this.currentUser.courtier_user[0].user_id,
                    courtier_id: this.currentUser.courtier_user[0].courtier_id,
                    clientId: this.prospectChosen.id,
                    clientType: this.prospectChosen.type,
                })
                .then((res) => {
                    if (res.data.success) {
                        res.data.data.forEach((element) => {
                            this.projetContratData.push({
                                id: element.id,
                                numero: element.police_no ? element.police_no : "NC",
                                source: element.source,
                                type: "CONTRAT",
                                ref_externe: null,
                                assureur_id: element.assureur_id,
                                risque_id: element.risque_id
                            });
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        backToListing(value) {
            this.interfaceSinistre = value;
        },
        recordSinistreReturned() {
            this.messageToast(message, "Succès", "success");
        },
        fetchAssureurList() {
            this.assureursOptions = [{ value: null, text: '-- Choisissez --' }]
            this.$http
                .post('/assureurs/fetchAssureurList')
                .then(res => {
                    if (res.data.success) {
                        res.data.data.forEach(el => {
                            this.assureursOptions.push({
                                value: el.id,
                                text: el.nom_assureur
                            })
                        })
                    }
                })
                .catch(err => {
                    this.assureursOptions = [{ value: null, text: '-- Choisissez --' }]
                    console.error(err)
                })
        },
        fetchProspectData() {
            this.$http
                .get("/prospect/getAllProspect")
                .then((r) => {
                    this.pushAndFusiondata(r.data[0].concat(r.data[1]));
                })
                .catch((err) => {
                    this.showLoading = false;
                    console.log(err);
                });
        },
        pushAndFusiondata(array) {
            this.prospect_filtred = [];
            array.forEach((item) => {
                this.prospect_filtred.push({
                    id: item.id,
                    client:
                        item.type === "PERSONNE_MORALE"
                            ? item.denomination_commercial
                            : item.nom + " " + item.prenom,
                    nom: null,
                    prenom: null,
                    categorie: item.categorie,
                    type: item.type,
                });
            });
            this.prospectData = this.$_.orderBy(
                this.prospect_filtred,
                ["type"],
                ["desc"]
            );
        },

        showProspect(id, type) {
            if (type == "PERSONNE_PHYSIQUE") {
                this.$router.push({
                    name: "tiers-list",
                    params: {
                        id: id,
                        type: type,
                        interfaceChosen: "Professionnel",
                    },
                });
            } else {
                this.$router.push({
                    name: "tiers-list",
                    params: {
                        id: id,
                        type: "PERSONNE_MORALE",
                        interfaceChosen: "Entreprise",
                    },
                });
            }
        },
        changeText(statut) {
            switch (statut) {
                case 'EN_COURS':
                    return 'En cours'
                    break
                case 'CLOS':
                    return 'Clos'
                    break
                case 'REOUVERT':
                    return 'Réouvert'
                    break
            }
        },
        changeTextTypeSinistre(type) {

            switch (type) {
                case 'DOMMAGE_CORPOREL':
                    return 'Dommage corporel'
                    break
                case 'DOMMAGE_MATERIEL':
                    return 'Dommage matériel'
                    break
                case 'DOMMAGE_CORPOREL_ET_MATERIEL':
                    return 'Dommage corporel et matériel'
                    break
            }

        },
        fetchDataCommercialByCourtier() {
            this.gestionnaireOptions = []
            this.$http
                .post('/prospect/fetchDataCommercialByCourtier', { courtier_id: this.currentUser.courtier_user[0].courtier_id })
                .then(res => {
                    if (res.data.success) {
                        res.data.data.forEach(el => {
                            this.gestionnaireOptions.push({
                                id: el.user_id,
                                type: el.type,
                                nom: el.nom
                            })
                        })
                    }
                })
                .catch(err => {
                    this.gestionnaireOptions = []
                    console.error(err)
                })
        },

        // actions data
        editSinistre(data) {


            this.sinistreForEdit.id = data.id
            this.sinistreForEdit.numero_sinistre = data.numero_sinistre
            this.sinistreForEdit.canal_sinistre = data.canal_sinistre

            this.sinistreForEdit.date_acc_recep = data.date_acc_recep ? data.date_acc_recep : null,
            this.sinistreForEdit.is_client = data.sinistre_client ? true : false;
            this.sinistreForEdit.is_contrat = data.sinistre_reference ? true : false;
            this.sinistreForEdit.courtier_id = data.courtier_id;
            if (data.sinistre_client) {
                this.sinistreForEdit.sinistree = {
                    id: data.sinistre_client.id,
                    type: data.sinistre_client.type,
                    categorie: data.sinistre_client.categorie,
                    nameClient : data.sinistre_client.type === "PERSONNE_MORALE"
                    ? data.sinistre_client.denomination_commercial
                    : data.sinistre_client.nom +
                    " " +
                    data.sinistre_client.prenom,
                    client:
                        data.sinistre_client.type === "PERSONNE_MORALE"
                            ? data.sinistre_client.denomination_commercial
                            : data.sinistre_client.nom +
                            " " +
                            data.sinistre_client.prenom,
                    nom: null,
                    prenom: null,
                };
            } else {
                this.sinistreForEdit.sinistree.nom = data.nom_sinistre;
                this.sinistreForEdit.sinistree.prenom = data.prenom_sinistre;

            }


            if (data.sinistre_reference) {
                if (data.referancable_type.includes("Contrat")) {
                    this.sinistreForEdit.reference = {
                        id: data.sinistre_reference.id,
                        numero: data.sinistre_reference.police_no
                            ? data.sinistre_reference.police_no
                            : "NC",
                        source: data.sinistre_reference.source,
                        type: "CONTRAT",
                        ref_externe: null,
                        assureur_id: data.sinistre_reference.assureur_id,
                        risque_id: data.sinistre_reference.risque_id
                    };
                }

            } else {
                this.sinistreForEdit.reference.ref_externe = data.ref_externe;
                this.sinistreForEdit.risque_id = data.risque_id
                this.sinistreForEdit.assureur_id = data.assureur_id
            }


            this.sinistreForEdit.statut = data.statut ? data.statut : null
            this.sinistreForEdit.date_sinistre = data.date_sinistre
            this.sinistreForEdit.date_declaration = data.date_declaration
            this.sinistreForEdit.responsabilite = data.responsabilite
            this.sinistreForEdit.type = data.type_sinistre
            this.sinistreForEdit.montant = data.montant_sinistre
            this.sinistreForEdit.expert = data.expert
            this.sinistreForEdit.isDelegation = data.isDelegation

            this.sinistreForEdit.date_cloture = data.date_cloture

            if (data.gestionnaire) {
                this.sinistreForEdit.gestionnaire = {
                    id: data.gestionnaire.personne_physique.user_id,
                    nom: `${data.gestionnaire.personne_physique.prenom} ${data.gestionnaire.personne_physique.nom}`,
                    type: data.gestionnaire.personne_physique.type,
                }
            }

            this.isUpdate = true



        },
        recordContratReturned(contrat, message) {
            if (!this.isUpdate) {
                this.rows.push(contrat[0])
                this.rowsConst.push(contrat[0])
                this.messageToast(message)
            } else {
                const indexContrat = this.rows.findIndex(el => el.id === contrat[0].id)
                this.rows.splice(indexContrat, 1)
                this.rows.push(contrat[0])

                const indexContratConst = this.rowsConst.findIndex(el => el.id === contrat[0].id)
                this.rowsConst.splice(indexContratConst, 1)
                this.rowsConst.push(contrat[0])
                // // eslint-disable-next-line prefer-destructuring
                // this.rows[indexContrat] = contrat[0]
                // // eslint-disable-next-line prefer-destructuring
                // this.rowsConst[indexContrat] = contrat[0]
                this.messageToast(message)
            }
        },
        listassureurfilter() {
            this.assureur_list = [{
                value: null,
                text: '-- Choisissez --'
            }]
            this.rows.forEach(el => {
                this.assureur_list.push({
                    value: el.assureur_id,
                    text: el.assureur,
                })
            })
            this.assureur_options_list = Array.from(new Set(this.assureur_list.map(a => a.value)))
                .map(id => {
                    return this.assureur_list.find(a => a.value === id)
                })
        },
        fetchSinistersList() {
            this.showLoading = true
            this.$http
                .get(`/sinistre/fetchDataSinistresByContrat/${this.contartIdType.itemId}`)
                .then(res => {
                    if (res.data.success) {
                        this.rows = res.data.data
                        this.rowsConst = res.data.data
                        this.filterData()
                        this.listassureurfilter()
                        this.$emit('count-sinistre-refreshed', this.rowsConst.length)
                        this.showLoading = false
                    }
                })
                .catch(err => {
                    this.showLoading = false
                    console.error(err)
                })

        },
        filterData() {
            console.log("11111111111111")
            console.log(this.selectedStatus)
            this.rows = this.rowsConst

            if (this.selectedAssureur) this.rows = this.rows.filter(item => item.assureur_id == this.selectedAssureur)
            if (this.selectedDelegation != null) this.rows = this.rows.filter(item => item.isDelegation == this.selectedDelegation)
            if (this.selectedStatus) this.rows = this.rows.filter(item => item.statut == this.selectedStatus)
            if (this.selectedRisque) this.rows = this.rows.filter(item => item.risque_id == this.selectedRisque)
            if (this.selectedTypeSinistre) this.rows = this.rows.filter(item => item.type_sinistre == this.selectedTypeSinistre)

            if (this.filtre.date_sinistre_entre_le) {
                this.rows = this.rows.filter(
                    (item) =>
                        new Date(
                            moment(item.date_sinistre, "DD/MM/YYYY").format("YYYY-MM-DD")
                        ) >=
                        new Date(
                            moment(
                                this.filtre.date_sinistre_entre_le,
                                "DD/MM/YYYY"
                            ).format("YYYY-MM-DD")
                        )
                );
            }
            if (this.filtre.date_sinistre_el_le) {
                this.rows = this.rows.filter(
                    (item) =>
                        new Date(
                            moment(item.date_sinistre, "DD/MM/YYYY").format("YYYY-MM-DD")
                        ) <=
                        new Date(
                            moment(this.filtre.date_sinistre_el_le, "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        )
                );
            }


        },
        clearAllFilter() {
            this.selectedDelegation = null
            this.selectedAssureur = null
            this.selectedStatus = null
            this.filtre.date_sinistre_entre_le = null
            this.filtre.date_sinistre_el_le = null
            this.selectedRisque = null
            this.selectedTypeSinistre = null
            this.recherche = null


        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

div.custom-table-bottom>div>.vgt-responsive {
    min-height: 0 !important;
}
</style>
